var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.pfizer)?_c('span',{staticClass:"orderTag2",class:{
    'orderTagCancel2':(_vm.status==-1),
    'orderTagOutTime2':(_vm.status==-2),
    'orderTagWait2':((_vm.status!=0&&!_vm.status)||_vm.status=='-100'),
    'orderTagOn2':(_vm.status==0||_vm.status==1||_vm.status==2||_vm.status==4),
    'orderTagOver2':_vm.status==10,
    'orderTagIng2':_vm.status==30
    }},[_c('span',[_vm._v(_vm._s(_vm.statusTxt))])]):_c('span',{staticClass:"orderTag",class:{
    'orderTagCancel':(_vm.status==-1),
    'orderTagOutTime':(_vm.status==-2),
    'orderTagWait':((_vm.status!=0&&!_vm.status)||_vm.status=='-100'),
    'orderTagOn':(_vm.status==0||_vm.status==1||_vm.status==2||_vm.status==4),
    'orderTagOver':_vm.status==10,
    'orderTagIng':_vm.status==30
    }},[_c('span',[_vm._v(_vm._s(_vm.statusTxt))]),_c('span',{staticClass:"orderTail"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }