<template>
    <component :is="recommend_"></component>
</template>
<script>
import { mapGetters } from "vuex";
import recommend_crossevent from '@/template/recommend/recommend_crossevent.vue'
import recommend_pfizer from '@/template/recommend/recommend_pfizer.vue'
import {
    SmartStorage
} from 'smart-core-util'
export default {
    components: {
        recommend_crossevent,
        recommend_pfizer,
        
    },
    computed: {
        ...mapGetters(['event','mealRules']),
        recommend_: {
            get: function() {
                var component_code = SmartStorage.get('tenant_code') || 'crossevent';
                // return ('recommend_' + component_code);  
                return ('recommend_pfizer');  
            }    
        }
    },
}
</script>
