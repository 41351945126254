<template>
    <span>
        <span v-if='pfizer' class="orderTag2" :class="{
        'orderTagCancel2':(status==-1),
        'orderTagOutTime2':(status==-2),
        'orderTagWait2':((status!=0&&!status)||status=='-100'),
        'orderTagOn2':(status==0||status==1||status==2||status==4),
        'orderTagOver2':status==10,
        'orderTagIng2':status==30
        }">
            <span>{{statusTxt}}</span>
        </span>
        <span v-else class="orderTag" :class="{
        'orderTagCancel':(status==-1),
        'orderTagOutTime':(status==-2),
        'orderTagWait':((status!=0&&!status)||status=='-100'),
        'orderTagOn':(status==0||status==1||status==2||status==4),
        'orderTagOver':status==10,
        'orderTagIng':status==30
        }">
            <span>{{statusTxt}}</span>
            <span class="orderTail"></span>
        </span>

    </span>
</template>

<script>
    export default {
        props: ['status', 'statusTxt','pfizer'],
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
    @import "./eventTag";
</style>